import { IUserActivityInfo } from "./user-activity-info";
import { EMPTY_USER_TYPE } from "./user-type";

export interface IUserLite extends IUserActivityInfo {
    account_id: number;
    user_info_id: number;
    name: string;
    surname: string;
    middlename: string|null;
    user_type_id: number;
}
export const EMPTY_USER_LITE : IUserLite = {
    account_id: 0,
    user_info_id: 0,
    name: '',
    surname: '',
    middlename: null,
    user_type_id: EMPTY_USER_TYPE.id,
};