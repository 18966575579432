import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { InfoAlertComponent, InputRefTrimValueDirective, LocalStorageService, LoggerService } from '@webapp-nx-repo/lib-shared-common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, EMPTY, map, startWith, Subject, tap } from 'rxjs';
import { UsersResolverT } from './users-resolver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { EMPTY_USER_INFO, IUserInfo } from '../../model/user-info';
import { EMPTY_USER, IUser } from '../../model/user';
import { ACCOUNT_ROUTER_TOKENS } from '../../account/routes/router-tokens';

enum UI_FilterType { 
    ALL = 'all',
    USERS_WITH_ACCOUNT = 'users_with_account',
    USERS_WITHOUT_ACCOUNT = 'users_without_account',
    ACCOUNT_WITHOUT_USER = 'account_without_users',
}

class UI_Item {
    public label: string = "";
    public search_string: string = "";

    constructor(
        public account: IUser,
        public info: IUserInfo,
    ) {}

    init() {
        // init label
        if(this.info.id){
            this.label = this.info.name +' ' + this.info.surname;
        } else if(this.account) {
            this.label = this.account.email;
        }

        // init search string
        this.search_string = "";
        if(this.info.id){
            this.search_string = this.info.name.trim().toLowerCase() + ' ' + this.info.surname.trim().toLowerCase();
            if (this.info.middlename) {
                this.search_string += ' ' + this.info.middlename.trim().toLowerCase();
            }
        }
        if(this.account){
            this.search_string += (this.info.id?' ':'') + this.account.email.trim().toLowerCase();
        }
    }
};

@Component({
	selector: 'lib-users',
	imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        InfoAlertComponent,
    ],
	templateUrl: './users.component.html',
	styleUrl: './users.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent {
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    menuService = inject(MenuService);
    private readonly logger = inject(LoggerService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly fb = inject(FormBuilder);

    filter$ = new Subject<KeyboardEvent>();

    data$ = this.route.data.pipe(
        map( data => data['data'] as UsersResolverT ),
        map( ([accounts, users]) => {

            // add all accounts
            const ui_users = accounts.map( account => new UI_Item(account, EMPTY_USER_INFO) );

            // if a user has an account, find the account and add the user
            // otherwise create a new item with the user
            users.forEach( user => {
                if( user.user_id === null ) {
                    ui_users.push(new UI_Item(EMPTY_USER, user));
                } else {
                    const x = ui_users.find(x => x.account!.id === user.user_id);
                    x!.info = user;
                }
            });

            ui_users.forEach( item => { item.init(); });
            ui_users.sort((a, b) => a.label.localeCompare(b.label));

            return {
                ui_users,
            };
        }),
    );

    filterType = new BehaviorSubject<UI_FilterType>(
        this.localStorageService.has('UsersComponent.filterType') ?
            this.localStorageService.get('UsersComponent.filterType') :
            UI_FilterType.ALL
    );
    filterType$ = this.filterType.asObservable().pipe(
        tap( filter_type => {
            this.localStorageService.set('UsersComponent.filterType', filter_type);
        }),
    );

    users$ = combineLatest([
        this.filter$.pipe(
            map( (event:KeyboardEvent) => {
                const value = (event.target as HTMLInputElement).value;
                return value.trim().toLowerCase();
            }),
            startWith(''),
            debounceTime(200),
            distinctUntilChanged(),
        ),
        this.filterType$,
        this.data$,
    ]).pipe(
        map(([filter, filterType, data]): UI_Item[] => {
            return data.ui_users
                .filter((item: UI_Item) => 
                    (
                        (filter.length === 0) ||
                        item.search_string.includes(filter)
                    )
                    && 
                    (
                        (filterType === UI_FilterType.ALL) ||
                        (filterType === UI_FilterType.USERS_WITHOUT_ACCOUNT     && (item.info.id && !item.account.id))    || 
                        (filterType === UI_FilterType.USERS_WITH_ACCOUNT        && (item.info.id && item.account.id))     ||
                        (filterType === UI_FilterType.ACCOUNT_WITHOUT_USER      && (item.account.id && !item.info.id))
                    )
                );
        }),
    );
    

    constructor() {
		this.menuService.header.set({
			leftCmd: {
				icon: 'keyboard_arrow_left',
				fn: () => {
					this.router.navigate(['../'], { relativeTo: this.route });
				},
			},
			title: 'Utenti',
			rightCmd: [
                {
                    icon: 'filter_alt',
                    label: 'Filtra',
                    cmds: [
                        {
                            icon: 'list',
                            label: 'tutto',
                            fn: () => this.filterType.next(UI_FilterType.ALL)
                        },
                        {
                            icon: 'info',
                            label: 'user senza account',
                            fn: () => this.filterType.next(UI_FilterType.USERS_WITHOUT_ACCOUNT)
                        },
                        {
                            icon: 'info',
                            label: 'acc. senza user',
                            fn: () => this.filterType.next(UI_FilterType.ACCOUNT_WITHOUT_USER)
                        },
                        {
                            icon: 'info',
                            label: 'acc. con user',
                            fn: () => this.filterType.next(UI_FilterType.USERS_WITH_ACCOUNT)
                        },
                    ]
                }
            ],
		});
	}

    openInfo(id: number) {
        this.router.navigate([ACCOUNT_ROUTER_TOKENS.USER_INFO, id], { relativeTo: this.route });
    }
    openAccount(id: number) {
        this.router.navigate([ACCOUNT_ROUTER_TOKENS.USER_ACCOUNT, id], { relativeTo: this.route });
    }
}
