import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	computed,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormBuilder,
	FormControl,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
	ErrorAlertComponent,
	SafeHtmlPipe,
} from '@webapp-nx-repo/lib-shared-common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { MatDialog } from '@angular/material/dialog';
import { LibAuthService } from '../../../service/lib-auth.service';
import { PasswordConfirmationDialogComponent } from '../../../password-confirmation-dialog/password-confirmation-dialog.component';
import { EMPTY_USER } from '../../../model/user';

@Component({
	imports: [
		CommonModule,
		ErrorAlertComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSlideToggleModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatSnackBarModule,
		SafeHtmlPipe,
	],
	templateUrl: './two-factor.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoFactorComponent {
	private readonly destroyRef = inject(DestroyRef);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);
	private readonly fb = inject(FormBuilder);
	private readonly snackBar = inject(MatSnackBar);
	private readonly libAuthService = inject(LibAuthService);
	private readonly menuService = inject(MenuService);

	public dialog = inject(MatDialog);

	private readonly logged_user = toSignal(
		this.libAuthService.getLoggedUser(),
		{ initialValue: EMPTY_USER }
	);

	saving = signal<boolean>(false); // save enable disable
	error = signal<string | null>(null);

	twoFA_enabled = computed(() => this.logged_user().two_factor);
	twoFA_confirmed = computed(
		() => !!this.logged_user().two_factor_confirmed_at
	);

	twoFA: FormControl<boolean> = this.fb.nonNullable.control<boolean>(
		this.twoFA_enabled()
	);
	twoFA_confirm: FormControl<string | null> = this.fb.control<string | null>(
		null,
		[Validators.required, Validators.minLength(3)]
	);

	svg_qr_code = signal<string | undefined>(undefined);

	constructor() {
		this.menuService.header.set({
			leftCmd: { icon: 'keyboard_arrow_left', fn: () => this.back() },
			title: 'Autenticazione a 2 fattori',
			rightCmd: null,
		});
	}

	ngOnInit(): void {
		this.twoFA.valueChanges.subscribe((slide_value) => {
			if (
				(slide_value && !this.twoFA_enabled()) ||
				(!slide_value && this.twoFA_enabled())
			) {
				this.try_toggle2FA(slide_value);
			}
		});
	}

	back(): void {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	/*
        By default, the features array of the fortify configuration file instructs Fortify's 
        two factor authentication settings to require password confirmation before modification. 
        Therefore, your application should implement Fortify's password confirmation feature 
        before continuing.
    */

	try_toggle2FA(slide_value: boolean) {
		// console.log(`toggle2FA(${slide_value?'true':'false'}) ...`);
		this.start_saving(this.twoFA);

		this.libAuthService
			.getConfirmPasswordStatus()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (passwordConfirmed) => {
					if (!passwordConfirmed) {
						const dialogRef = this.dialog.open(
							PasswordConfirmationDialogComponent
						);
						dialogRef.afterClosed().subscribe((confirmed) => {
							if (confirmed) {
								return slide_value
									? this.enable2FA()
									: this.disable2FA();
							} else {
								this.stop_saving_error<boolean>(
									'Password non valida',
									this.twoFA,
									!slide_value
								);
							}
						});
					} else {
						return slide_value
							? this.enable2FA()
							: this.disable2FA();
					}
				},
				error: () => {
					// non riesco ad aprire dialog ?
					this.stop_saving_error<boolean>(
						'Errore generico',
						this.twoFA
					);
				},
			});
	}

	private enable2FA() {
		// this.start_saving(this.twoFA); is already started in "try_toggle2FA"

		this.libAuthService
			.enable2FA()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (res) => {
					this.stop_saving_ok(
						'Autenticazione a 2 fattori abilitata.',
						this.twoFA
					);
					this.showCode();
				},
				error: (err: string) => {
					this.stop_saving_error<boolean>(
						'Errore generico.',
						this.twoFA,
						false
					);
				},
			});
	}
	private disable2FA() {
		// this.start_saving(this.twoFA); is already started in "try_toggle2FA"

		this.libAuthService
			.disable2FA()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (res) => {
					this.stop_saving_ok(
						'Autenticazione a 2 fattori disabilitata.',
						this.twoFA
					);
				},
				error: (err: string) => {
					this.stop_saving_error<boolean>(
						'Errore generico.',
						this.twoFA,
						true
					);
				},
			});
	}

	showCode() {
		this.start_saving(this.twoFA_confirm);

		this.libAuthService
			.getQrCode()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (qr_code: string) => {
					this.stop_saving_ok(
						'QR Code per autenticazione',
						this.twoFA_confirm
					);
					this.svg_qr_code.set(qr_code);
				},
				error: (err: string) => {
					this.stop_saving_error<string | null>(
						'Errore nel recupero del qr code',
						this.twoFA_confirm
					);
					this.svg_qr_code.set(undefined);
				},
			});
	}

	// Nota: anche per la conferma serve sbloccare la "risors" con la password.
	// se faccio enable ma non confermo e il giorno dopo voglio confermare, mi torna
	// errore: Password confirmation required. (423 Locked)
	//
	try_confirmTwoFactorAuthentication() {
		this.start_saving(this.twoFA_confirm);

		this.libAuthService
			.getConfirmPasswordStatus()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (passwordConfirmed) => {
					if (passwordConfirmed) {
						return this.confirmTwoFactorAuthentication();
					} else {
						const dialogRef = this.dialog.open(
							PasswordConfirmationDialogComponent
						);
						dialogRef.afterClosed().subscribe((confirmed) => {
							if (confirmed) {
								return this.confirmTwoFactorAuthentication();
							} else {
								this.stop_saving_error<string | null>(
									'Password non valida',
									this.twoFA_confirm
								);
							}
						});
					}
				},
				error: () => {
					// non riesco ad aprire dialog ?
					this.stop_saving_error<string | null>(
						'Errore generico',
						this.twoFA_confirm
					);
				},
			});
	}

	private confirmTwoFactorAuthentication() {
		// this.start_saving(this.twoFA_confirm); is already started in "try_confirmTwoFactorAuthentication"

		const authentication_code: string = this.twoFA_confirm.value!;

		this.libAuthService
			.confirmedTwoFactorAuthentication(authentication_code)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (x: any) => {
					this.svg_qr_code.set(undefined);
					this.stop_saving_ok(
						'Autenticazione confermata',
						this.twoFA_confirm
					);
				},
				error: (err: string) => {
					this.stop_saving_error<string | null>(
						'Codice otp invalido',
						this.twoFA_confirm
					);
				},
			});
	}

	private start_saving(ctrl: FormControl | undefined = undefined) {
		this.saving.set(true);
		this.error.set(null);
		if (ctrl !== undefined) {
			ctrl.disable({ emitEvent: false });
		}
	}

	private stop_saving_ok(msg: string, ctrl: FormControl | null = null) {
		this.saving.set(false);
		this.error.set(null);
		if (ctrl) {
			ctrl.enable({ emitEvent: false });
		}
		this.snackBar.open(msg, '', { duration: 2000 });
	}

	private stop_saving_error<T>(
		err: string,
		ctrl: FormControl<T> | undefined = undefined,
		value: T | undefined = undefined
	) {
		this.saving.set(false);
		this.error.set(err);
		if (ctrl !== undefined) {
			if (value !== undefined) {
				ctrl.setValue(value, { emitEvent: false });
			}
			ctrl.enable({ emitEvent: false });
		}
	}
}
