import {
	booleanAttribute,
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	inject,
	Input,
	input,
	OnInit,
	Provider,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	TouchedChangeEvent,
	Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InputRefTrimValueDirective } from '../directives/trim-value-accessor.directive';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	startWith,
	tap,
} from 'rxjs';
import { ICityDetails } from './city-details';
import { ICity } from '../models/city';

const CITY_INFO_VALUE_PROVIDER: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CityControlComponent),
	multi: true,
};

@Component({
	selector: 'lib-city-control',
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatAutocompleteModule,
		MatSelectModule,
		InputRefTrimValueDirective,
	],
	templateUrl: './city-control.component.html',
	styleUrl: './city-control.component.scss',
	providers: [CITY_INFO_VALUE_PROVIDER],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityControlComponent implements OnInit, ControlValueAccessor {
	@Input({ required: true }) set cities(cities: ICity[]) {
		this._cities.next(cities);
	}
	@Input({ required: true }) set countries(countries: string[]) {
		this._countries.next(countries);
	}
	@Input({ transform: booleanAttribute }) showAddress: boolean = false;
	@Input({ transform: booleanAttribute }) showZip: boolean = false;

	readonly italian_cities = signal<boolean>(true);

	private readonly _cities = new BehaviorSubject<ICity[]>([]);
	cities$ = this._cities.asObservable();

	private readonly _countries = new BehaviorSubject<string[]>([]);
	countries$ = this._countries.asObservable();

	private readonly fb = inject(FormBuilder);

	formData: FormGroup<{
		country: FormControl<string | null>;
		address: FormControl<string | null>; // ?
		city: FormControl<ICity | null>;
		zip: FormControl<string | null>;
		province: FormControl<string | null>;
	}> = this.fb.group({
		country: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.minLength(1),
			Validators.maxLength(191),
		]),
		address: this.fb.control<string | null>(null, [
			Validators.minLength(1),
			Validators.maxLength(191),
		]), // ? => remove
		city: this.fb.control<ICity | null>({ value: null, disabled: true }, [
			Validators.required,
			Validators.minLength(1),
			Validators.maxLength(191),
		]),
		zip: this.fb.control<string | null>({ value: null, disabled: true }, [
			Validators.minLength(5),
			Validators.maxLength(5),
		]),
		province: this.fb.control<string | null>(
			{ value: null, disabled: true },
			[
				Validators.required,
				Validators.minLength(1),
				Validators.maxLength(64),
			]
		),
	});

	filtered_cities$ = combineLatest([
		this.cities$,
		this.formData.controls.city.valueChanges.pipe(
			startWith(''),
			debounceTime(200),
			filter(
				(city) =>
					city !== null &&
					((typeof city === 'string' && city.length > 2) ||
						typeof city !== 'string')
			),
			map((city) =>
				typeof city === 'string' ? city : (city as ICity).city
			),
			distinctUntilChanged()
		),
	]).pipe(
		map(([cities, city]) =>
			city ? this._filter(city, cities) : cities.slice()
		)
	);

	private _filter(city: string, cities: ICity[]): ICity[] {
		const filterValue = city.toLowerCase();
		const rv = cities.filter((option) =>
			option.city.toLowerCase().includes(filterValue)
		);
		return rv;
	}

	displayFn(value: ICity | string | null): string {
		if (value) {
			if (typeof value === 'string') {
				return value;
			} else {
				return value.city;
			}
		} else {
			return '';
		}
	}

	onOptionCitySelected(option: ICity | null) {
		if (option) {
			if (this.showZip) {
				this.formData.controls.zip.setValue(option.zip!);
			}
			this.formData.controls.province.setValue(option.province);
		}
	}

	private onChange!: Function;
	private onTouched!: Function;

	ngOnInit(): void {
		this.formData.controls.country.valueChanges.subscribe((country) => {
			this.italian_cities.set(country === 'Italia');
			country ? this.enableCity() : this.disableCity();
		});

		// Fwd OnChange
		this.formData.valueChanges.subscribe((value) => {
			if (this.onChange) {
				this.onChange(this.readValue());
			}
		});

		// Fwd OnTouch
		this.formData.events.subscribe((event) => {
			if (this.onTouched && event instanceof TouchedChangeEvent) {
				this.onTouched();
			}
		});
	}

	enableCity() {
		this.formData.controls.city.enable();
		this.formData.controls.zip.enable();
		this.formData.controls.province.enable();
	}
	disableCity() {
		this.formData.controls.city.disable();
		this.formData.controls.zip.disable();
		this.formData.controls.province.disable();
	}

	writeValue(obj: ICityDetails | null): void {
		if (obj) {
			this.italian_cities.set(obj.country === 'Italia');
			obj.country ? this.enableCity() : this.disableCity();

			this.formData.patchValue({
				country: obj.country,
				city: obj.city,
				zip: obj.city?.zip,
				province: obj.city?.province,
			});
			if (this.showAddress && obj.address) {
				this.formData.controls.address.setValue(obj.address);
			}
		} else {
			this.formData.reset();
		}
	}
	private readValue(): ICityDetails {
		const cityObj: ICity | null = this.formData.controls.city.value
			? { ...this.formData.controls.city.value }
			: null;
		if (cityObj) {
			cityObj.province = this.formData.controls.province.value!;
			if (this.showZip) {
				cityObj.zip = this.formData.controls.zip.value!;
			} else {
				delete cityObj.zip;
			}
		}

		const item: ICityDetails = {
			country: this.formData.controls.country.value,
			city: cityObj,
			// address ?
		};
		if (this.showAddress) {
			item['address'] = this.formData.controls.address.value;
		}
		return item;
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(cbkFn: any): void {
		this.onTouched = cbkFn;
	}
	setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.formData.disable() : this.formData.enable();
	}
}
