import { IUserActivityInfo } from "./user-activity-info";

// Uno user di tipo X può avere più ruoli, ognuno con i suoi permessi.
export interface IUserType extends IUserActivityInfo {
    id: number;
    name: string;
    description: string;
}
export const EMPTY_USER_TYPE: IUserType = {   
    id: 0,  name: 'unknown', description: 'unknown'
};