import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { ACCOUNT_ROUTER_TOKENS } from '../routes/router-tokens';

@Component({
	selector: 'lib-account',
	imports: [CommonModule, RouterLink, MatListModule, MatIconModule],
	templateUrl: './account.component.html',
	styleUrl: './account.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountComponent {
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	menuService = inject(MenuService);

	readonly ACCOUNT_ROUTER_TOKENS = ACCOUNT_ROUTER_TOKENS;

	constructor() {
		this.menuService.header.set({
			leftCmd: {
				icon: 'keyboard_arrow_left',
				fn: () => {
					this.router.navigate(['../'], { relativeTo: this.route });
				},
			},
			title: 'Account',
			rightCmd: null,
		});
	}
}
