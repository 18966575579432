<form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
            
    @if(userCanChangeType()) {
        <div class="border rounded p-4 bg-white flex flex-col mb-4 ">
            
            <h2 class="py-4 px-2 flex items-end">
                <mat-icon class="mr-3">person</mat-icon>
                <span class="font-bold">Tipo utente</span>
            </h2>

            <mat-form-field>
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="user_type_id">
                    @for (userType of user_types(); track userType.id) {
                        <mat-option [value]="userType.id">{{userType.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
    }

    <!-- Nome, Cognome, cf, phone, sez, stato civile -->
    <div class="border rounded p-4 bg-white flex flex-col">
        
        <h2 class="py-4 px-2 flex items-end">
            <mat-icon class="mr-3">badge</mat-icon>
            <span class="font-bold">Dati profilo</span>
        </h2>        

        <mat-form-field>
            <mat-label>Nome</mat-label>                
            <input inputRefTrimValue matInput type="text" formControlName="name" required>
            @if(formData.controls.name.errors?.['required']) {
                <mat-error>Inserire il tuo nome</mat-error>
            }
            @if(formData.controls.name.errors?.['maxLength']) {
                <mat-error>Nome troppo lungo</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>Cognome</mat-label>
            <input inputRefTrimValue matInput type="text" formControlName="surname" required>
            @if(formData.controls.surname.errors?.['required']) {
                <mat-error>Inserire il tuo cognome</mat-error>
            }
            @if(formData.controls.surname.errors?.['maxLength']) {
                <mat-error>Cognome troppo lungo</mat-error>
            }
        </mat-form-field>

        <mat-form-field>
            <mat-label>Secondo nome</mat-label>
            <input inputRefTrimValue matInput type="text" formControlName="middlename">
        </mat-form-field>

        @if(profile_cfg.cf) {
            <mat-form-field>
                <mat-label>Codice fiscale</mat-label>
                <input type="text" class="uppercase" matInput formControlName="cf">
                @if(formData.controls.cf!.invalid && formData.controls.cf!.hasError('required')) {
                    <mat-error>inserire codice fiscale</mat-error>
                }
                @if(formData.controls.cf!.invalid && formData.controls.cf!.hasError('minlength')) {
                    <mat-error>codice fiscale troppo corto</mat-error>
                }
                @if(formData.controls.cf!.invalid && formData.controls.cf!.hasError('maxlength')) {
                    <mat-error>codice fiscale troppo lungo</mat-error>
                }
            </mat-form-field>
        }
        
        @if(profile_cfg.phone) {
            <mat-form-field>
                <mat-label>Telefono</mat-label>
                <input type="text" matInput formControlName="phone">
                @if(formData.controls.phone!.invalid && formData.controls.phone!.hasError('required')) {
                    <mat-error>inserire nr di telefono</mat-error>
                }
                @if(formData.controls.phone!.invalid && formData.controls.phone!.hasError('minlength')) {
                    <mat-error>nr di telefono troppo corto</mat-error>
                }
                @if(formData.controls.phone!.invalid && formData.controls.phone!.hasError('maxlength')) {
                    <mat-error>nr di telefono troppo lungo</mat-error>
                }
            </mat-form-field>
        }
    </div>

    @if(profile_cfg.date_of_birth || profile_cfg.birth_place) {
        <!-- date_of_birth, comune, prov, -->
        <div class="border rounded p-4 mt-4 bg-white flex flex-col">

            <h2 class="py-4 px-2 flex items-end">
                <mat-icon class="mr-3">crib</mat-icon>
                <span class="font-bold">
                    @if (profile_cfg.date_of_birth && profile_cfg.birth_place) {
                        Data e luogo di nascita
                    } @else if (profile_cfg.date_of_birth) {
                        Data di nascita
                    } @else {
                        Luogo di nascita
                    }
                </span>
            </h2>

            @if(profile_cfg.date_of_birth) {
                <mat-form-field>
                    <mat-label>data di nascita</mat-label>
                    <input matInput [matDatepicker]="date_of_birth" formControlName="date_of_birth">
                    <mat-datepicker-toggle matSuffix [for]="date_of_birth"></mat-datepicker-toggle>
                    <mat-datepicker #date_of_birth></mat-datepicker>
                </mat-form-field>
            }

            @if(profile_cfg.birth_place) {
                <lib-city-control [countries] ="countries()" [cities]="cities_without_zip()" [showAddress]="false" [showZip]="false" formControlName="birth_place"/>
                @if(loadingCities()) {
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                }
            }
        </div>
    }

    
    @if(profile_cfg.place_of_residence) {
        
        <div class="border rounded p-4 mt-4 bg-white flex flex-col">

            <h2 class="py-4 px-2 flex items-end">
                <mat-icon class="mr-3">home</mat-icon>
                <span class="font-bold">Dati di residenza</span>
            </h2>

            <lib-city-control [countries] ="countries()" [cities]="cities()" [showAddress]="true" [showZip]="true" formControlName="place_of_residence"/>
            @if(loadingCities()) {
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }  
        </div>       
    }
    

    @if(profile_cfg.iban) {
        
        <div class="border rounded p-4 mt-4 bg-white flex flex-col">

            <h2 class="py-4 px-2 flex items-end">
                <mat-icon class="mr-3">account_balance</mat-icon>
                <span class="font-bold">IBAN</span>
            </h2>

            <mat-form-field>
                <mat-label>Iban per accredito stipendio</mat-label>
                <input type="text" matInput formControlName="iban">
                @if(formData.controls.iban!.invalid && formData.controls.iban!.hasError('minlength')) {
                    <mat-error>IBAN troppo corto</mat-error>
                }
                @if(formData.controls.iban!.invalid && formData.controls.iban!.hasError('maxlength')) {
                    <mat-error>IBAN troppo lungo</mat-error>
                }
            </mat-form-field>
            
        </div>       
    }

    <lib-ok-cancel 
        label_ok="SALVA"
        label_cancel="Indietro"
        [disable_ok]="formData.invalid || formData.pristine || saving()" 
        [loading_ok]="saving()"
        (onOK)="save()"
        (onCancel)="this.onCancel.emit()" />
    
</form>

@if (error()) {
    <lib-error-alert>{{ error() }}</lib-error-alert>
}
