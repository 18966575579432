import { DateTime } from "luxon";
import { IUserActivityInfo } from "./user-activity-info";
import { IUserType } from "./user-type";
import { IUser } from "./user";

export interface IUserInfo extends IUserActivityInfo {
    id: number;
    name: string;
    surname: string;
    middlename: string|null;

    cf: string | null;
    date_of_birth: DateTime | null;
    user_type_id: number | null;
    
    birth_country: string | null;
    birth_city: string | null;
    birth_prov: string | null;
    birth_zip: string | null;
    
    // place_of_residence
    por_country: string | null;
    por_address: string | null;
    por_city: string | null;
    por_zip: string | null;
    por_prov: string | null;
    
    phone: string | null;
    email: string | null;
    iban: string | null;
    
    user_id: number | null;
    
    user_type?: IUserType;
    user?: IUser;
}

export const EMPTY_USER_INFO : IUserInfo = {
    id: 0,
    name: '',
    surname: '',
    middlename: null,

    cf: null,
    date_of_birth: null,    
    user_type_id: null,

    birth_country: null,
    birth_city: null,
    birth_prov: null,
    birth_zip: null,

    por_country: null,
    por_address: null,
    por_city: null,
    por_zip: null,
    por_prov: null,

    phone: null,
    email: null,
    iban: null,
    user_id: null,
};

/*
 ALTER TABLE `gp_db`.`user_infos` 
ADD COLUMN `birth_country` VARCHAR(255) NULL AFTER `user_type_id`,
ADD COLUMN `birth_zip` VARCHAR(16) NULL AFTER `birth_prov`,
ADD COLUMN `por_country` VARCHAR(255) NULL AFTER `birth_zip`,
CHANGE COLUMN `cf` `cf` VARCHAR(255) NULL ,
CHANGE COLUMN `date_of_birth` `date_of_birth` DATE NULL ,
CHANGE COLUMN `nascita_comune` `birth_city` VARCHAR(255) NULL ,
CHANGE COLUMN `nascita_prov` `birth_prov` VARCHAR(255) NULL ,
CHANGE COLUMN `residenza_via` `por_street` VARCHAR(255) NULL ,
CHANGE COLUMN `residenza_comune` `por_city` VARCHAR(255) NULL ,
CHANGE COLUMN `residenza_cap` `por_zip` VARCHAR(16) NULL ,
CHANGE COLUMN `residenza_prov` `por_prov` VARCHAR(255) NULL ,
CHANGE COLUMN `phone` `phone` VARCHAR(255) NULL ,
CHANGE COLUMN `statino_iban` `iban` VARCHAR(255) NULL DEFAULT NULL ;
 */