<form [formGroup]="formData" autocomplete="off" novalidate>
    <div class="flex flex-col">

        <mat-form-field>
            <mat-label>Paese</mat-label>
            <mat-select formControlName="country" required>
                @for (item of (countries$ | async); track $index) {
                    <mat-option [value]="item">
                        {{ item }}
                    </mat-option>
                }            
            </mat-select>
        </mat-form-field>

        @if(showAddress) {
            <mat-form-field>
                <mat-label>via, piazza, ...</mat-label>
                <input inputRefTrimValue matInput type="text" formControlName="address">
                @if(formData.controls.address!.hasError('required')) {
                    <mat-error>Inserire indirizzo</mat-error>
                }
                @if(formData.controls.address!.hasError('minlength')) {
                    <mat-error>indirizzo troppo corto</mat-error>
                }
                @if(formData.controls.address!.hasError('maxlength')) {
                    <mat-error>indirizzo troppo lungo</mat-error>
                }
            </mat-form-field>
        }

        <mat-form-field>
            <mat-label>Comune</mat-label>            
            <input inputRefTrimValue matInput type="text" 
                formControlName="city" required 
                [matAutocomplete]="citiesCtrl" 
                [matAutocompleteDisabled]="!italian_cities()">
            <mat-autocomplete #citiesCtrl="matAutocomplete" [displayWith]="displayFn">
                @for (option of (filtered_cities$ | async); track $index; )
                {
                    <mat-option [value]="option" (click)="onOptionCitySelected(option)">
                        {{option.city}} 
                        @if(showZip) {
                            - {{ option.zip }} 
                        }
                        ({{option.province}})
                    </mat-option>
                }
            </mat-autocomplete>
            @if(formData.controls.city!.hasError('required')) {
                <mat-error>Inserire il comune</mat-error>
            }
            @if(formData.controls.city!.hasError('maxLength')) {
                <mat-error>Comune troppo lungo</mat-error>
            }
        </mat-form-field>
        
        <div class="flex flex-row" [ngClass]="{'gap-x-4': showZip}">
            
            @if(showZip) {
                <mat-form-field class="basis-1/2">
                    <mat-label>cap</mat-label>
                    <input inputRefTrimValue matInput type="text" formControlName="zip">
                    @if(formData.controls.zip!.hasError('required')) {
                        <mat-error>inserire codice postale</mat-error>
                    }
                    @if(formData.controls.zip!.hasError('minlength')) {
                        <mat-error>codice postale troppo corto</mat-error>
                    }
                    @if(formData.controls.zip!.hasError('maxlength')) {
                        <mat-error>codice postale troppo lungo</mat-error>
                    }
                </mat-form-field>
            }

            <mat-form-field [ngClass]="{'basis-1/2': showZip, 'basis-full': (!showZip)}">
                <mat-label>provincia</mat-label>
                <input inputRefTrimValue matInput type="text" formControlName="province">
                @if(formData.controls.province!.hasError('required')) {
                    <mat-error>inserire provincia</mat-error>
                }
                @if(formData.controls.province!.hasError('minlength')) {
                    <mat-error>provincia troppo corta</mat-error>
                }
                @if(formData.controls.province!.hasError('maxlength')) {
                    <mat-error>provincia troppo lunga</mat-error>
                }
            </mat-form-field>
        
        </div>
    

    </div>
</form>