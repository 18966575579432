import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
    standalone: true
})
export class SafeHtmlPipe implements PipeTransform {

    private sanitizer = inject(DomSanitizer);

    transform(value: string, ...args: unknown[]): unknown {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

}
