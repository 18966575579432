import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	ErrorAlertComponent,
	InfoAlertComponent,
	OkCancelComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { LibAuthService } from '../../../service/lib-auth.service';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';

@Component({
	imports: [
		CommonModule,
		ErrorAlertComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		InfoAlertComponent,
		OkCancelComponent,
	],
	templateUrl: './change-email.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeEmailComponent {
	private readonly destroyRef = inject(DestroyRef);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);
	private readonly fb = inject(FormBuilder);
	private readonly snackBar = inject(MatSnackBar);
	private readonly authService = inject(LibAuthService);
	private readonly menuService = inject(MenuService);

	readonly saving = signal<boolean>(false);
	readonly error = signal<string | null>(null);
	readonly email_verified_at =
		this.authService.loggedUser().email_verified_at;
	readonly mail_saved = signal<string | undefined>(undefined);

	formData: FormGroup<{
		email: FormControl<string | null>;
	}> = this.fb.group({
		email: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.email,
			Validators.minLength(6),
			Validators.maxLength(191),
		]),
	});

	constructor() {
		this.menuService.header.set({
			leftCmd: { icon: 'keyboard_arrow_left', fn: () => this.back() },
			title: 'Cambio Email',
			rightCmd: null,
		});
	}

	ngOnInit(): void {
		this.formData.setValue({
			email: this.authService.loggedUser().email,
		});
	}

	back(): void {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	save() {
		this.saving.set(true);
		const email = this.formData.controls.email.value!;
		this.authService
			.updateEmail(email)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.error.set('');
					this.saving.set(false);
					this.mail_saved.set(email);
					this.snackBar.open('Email modificata', '', {
						duration: 2000,
					});
					// this.back();
				},
				error: (err: string) => {
					this.saving.set(false);
					this.mail_saved.set(undefined);
					this.snackBar.open('Errore generico.', '', {
						duration: 2000,
					});
				},
			});
	}
}
