<div class="app-p4-mb8-mxauto-maxwscreenlg flex flex-col">

    <mat-form-field>
        <mat-label>Cerca</mat-label>
        <input class="pr-2" matInput (keyup)="filter$.next($event)">
        <span matTextPrefix><mat-icon>search</mat-icon></span>
    </mat-form-field>

    <div class="divide-y">
        @for (item of (users$|async); track $index) {

            <div class="flex justify-between items-center">
                
                <div class="grow shrink">
                    {{ item.label }}
                </div>
    
                <div class="grow-0 shrink-0">
                    <div class="flex items-center gap-x-3">
                        <button mat-icon-button [disabled]="item.info.id === 0" (click)="openInfo(item.info.id)">
                            <mat-icon>info</mat-icon>
                        </button>
                        <button mat-icon-button [disabled]="item.account.id === 0" (click)="openAccount(item.account.id)">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </div>
                </div>
    
            </div>

        } @empty {
            <lib-info-alert>Non risultano esserci utenti registrati.</lib-info-alert>
        }
    </div>
    


</div>