import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { LoggerService } from '@webapp-nx-repo/lib-shared-common';
import { ACCOUNT_ROUTER_TOKENS } from '../../account/routes/router-tokens';
import { LibAuthService } from '../../service/lib-auth.service';
import { AUTH_ROUTER_TOKENS } from '../routes/router-tokens';

export function VerifyEmailRouteGuard() {
	return (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		const logger = inject(LoggerService);

		const routes_to_ignore = [
			`/${AUTH_ROUTER_TOKENS.EMAIL_NOT_VERIFIED}`,
			`/${ACCOUNT_ROUTER_TOKENS.BASE_URL}`,
			`/${ACCOUNT_ROUTER_TOKENS.BASE_URL}/${ACCOUNT_ROUTER_TOKENS.SECURITY}`,
			`/${ACCOUNT_ROUTER_TOKENS.BASE_URL}/${ACCOUNT_ROUTER_TOKENS.SECURITY}/${ACCOUNT_ROUTER_TOKENS.SECURITY_MAIL}`,
		];

		const ignore_guard = routes_to_ignore.some((url) => url === state.url);

		if (ignore_guard) {
			// logger.log('skip verify email for '+childRoute.url[0].path);
			return true;
		}
		const authService = inject(LibAuthService);
		const router = inject(Router);
		const isEmailVerified = authService.isEmailVerified();
		// logger.log('isEmailVerified for '+state.url+' ? ' + (isEmailVerified?'Y':'N'));
		// isEmailVerified === true, non valuta neanche quello che viene dopo il || e continua la navigazione.
		// isEmailVerified === false, resetta la navigazione tornando un urlTree che punta alla pag. di login.
		return (
			isEmailVerified ||
			router.parseUrl(`/${AUTH_ROUTER_TOKENS.EMAIL_NOT_VERIFIED}`)
		);
	};
}
