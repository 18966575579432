import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { EMPTY_USER_INFO, IUserInfo } from '../../model/user-info';
import { IUserType } from '../../model/user-type';
import { IUser } from '../../model/user';
import { HttpUsersService } from '../../service/http-users.service';
import { getRouteParameter } from '@webapp-nx-repo/lib-shared-common';


export type UserInfoResolverT = [IUserInfo, IUserType[]];


export const userInfoResolver: ResolveFn<UserInfoResolverT> = 
(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
)
: Observable<UserInfoResolverT> => {

    const s = inject(HttpUsersService);

    const user_info_id = getRouteParameter(
		route.paramMap,
		'Identificativo del user_info_id',
		'user_info_id'
	);

	if (user_info_id === undefined) {
		return throwError(() => 'undefined user_info_id');
	}
    
    return forkJoin([
        user_info_id === 0 ? 
            of(EMPTY_USER_INFO) : 
            s.user_info.get(user_info_id),
        s.user_types.all(),
    ]); 
}