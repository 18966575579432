import { DateTime } from "luxon";

export interface IUserActivityInfo {
    created_at?: DateTime;
    user_create_id?: number;
    updated_at?: DateTime;
    user_update_id?: number;
    deleted_at?: DateTime | null;
}

export const EMPTY_USER_ACTIVITY_INFO : IUserActivityInfo = {
    created_at: DateTime.now(),
    user_create_id: 0,
    updated_at: DateTime.now(),
    user_update_id: 0,
    deleted_at: null,
};
    