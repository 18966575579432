<div class="app-p4-mb8-mxauto-maxwscreenlg">
    <p class="p-4">
        Clicca sulla sezione che desideri modificare.
    </p>

    <mat-nav-list>
        
        <mat-list-item [routerLink]="[ACCOUNT_ROUTER_TOKENS.SECURITY_MAIL]">        
            <mat-icon matListItemIcon>mail</mat-icon>
            <div matListItemTitle>Cambia la mail</div>
            <div matListItemLine>Modifica la mail con cui accedi.</div>
        </mat-list-item>

        <mat-list-item [routerLink]="[ACCOUNT_ROUTER_TOKENS.SECURITY_PWD]">        
            <mat-icon matListItemIcon>password</mat-icon>
            <div matListItemTitle>Cambia la password</div>
            <div matListItemLine>Cambia la password con cui accedi.</div>
        </mat-list-item>

        @if(auth_cfg.enable_two_factor) {
            <mat-list-item [routerLink]="[ACCOUNT_ROUTER_TOKENS.SECURITY_2FA]">        
                <mat-icon matListItemIcon>pin</mat-icon>
                <div matListItemTitle>Autenticazione a 2 fattori</div>
                <div matListItemLine>Aumenta la sicurezza con l&apos;autenticazione a due fattori.</div>
            </mat-list-item>
        }

    </mat-nav-list>
</div>