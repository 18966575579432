import { Routes } from '@angular/router';
import { AccountComponent } from '../account/account.component';
import { ACCOUNT_ROUTER_TOKENS } from './router-tokens';
import { SecurityComponent } from '../security/security.component';
import { ChangeEmailComponent } from '../security/change-email/change-email.component';
import { ChangePasswordComponent } from '../security/change-password/change-password.component';
import { TwoFactorComponent } from '../security/two-factor/two-factor.component';
import { ProfileComponent } from '../profile/profile.component';

export const ACCOUNT_ROUTES: Routes = [
    {
        path: '',
        component: AccountComponent
    },
    {
        path: ACCOUNT_ROUTER_TOKENS.SECURITY,
        children: [
            {
                path: '',
                component: SecurityComponent
            },
            {
                path: ACCOUNT_ROUTER_TOKENS.SECURITY_MAIL,
                component: ChangeEmailComponent,
            },
            {
                path: ACCOUNT_ROUTER_TOKENS.SECURITY_PWD,
                component: ChangePasswordComponent,
            },
            {
                path: ACCOUNT_ROUTER_TOKENS.SECURITY_2FA,
                component: TwoFactorComponent,
            },
        ]
    },    
    {
        path: ACCOUNT_ROUTER_TOKENS.PROFILE,
        component: ProfileComponent,
    },
];

