import { DateTime } from 'luxon';
import { IUser } from './user';
import { IUserInfo } from './user-info';

export class USER_ENTITY_TRANSFORMATION_FN {
	public static user = (x: IUser): void => {
		if (x.info) {
			USER_ENTITY_TRANSFORMATION_FN.userInfo(x.info);
		}
	};

	public static userInfo = (x: IUserInfo): void => {
		x.date_of_birth = DateTime.fromISO(`${x.date_of_birth}`);
	};
    public static userInfoSrv = (x: IUserInfo): any => {
        return {
            ...x,
            date_of_birth: x.date_of_birth ? x.date_of_birth.toISODate() : null,
        };
    }
}
