import { Injectable, inject } from '@angular/core';
import { LibAuthService } from './lib-auth.service';


@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly me = 'AuthService';
	private readonly libAuthService = inject(LibAuthService);

	loggedUser = this.libAuthService.loggedUser;

	/*
    getLoggedUser(): Observable<IUser> {
        return this.libAuthService.getLoggedUser();
    }
    */

	two_fa_enabled_and_confirmed(): boolean {
		return this.libAuthService.two_fa_enabled_and_confirmed();
	}

	logout() {
		return this.libAuthService.logout();
	}
}
