
<div class="app-p4-mb8-mxauto-maxwscreenlg">
    <p class="p-4">
        Clicca sulla sezione che desideri modificare.
    </p>

    <mat-nav-list>
        
        <mat-list-item [routerLink]="[ACCOUNT_ROUTER_TOKENS.PROFILE]">        
            <mat-icon matListItemIcon>person</mat-icon>
            <div matListItemTitle>Profilo</div>
            <div matListItemLine>Nome, cognome, data di nscita, indirizzo, ..</div>
        </mat-list-item>

        <mat-list-item [routerLink]="[ACCOUNT_ROUTER_TOKENS.SECURITY]">        
            <mat-icon matListItemIcon>security</mat-icon>
            <div matListItemTitle>Accesso e sicurezza</div>
            <div matListItemLine>Cambia mail, password, imposta accesso a 2 fattori</div>
        </mat-list-item>

        <ng-content></ng-content>

    </mat-nav-list>
</div>