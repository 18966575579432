import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandlerFn,
	HttpInterceptorFn,
	HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoggerService } from '@webapp-nx-repo/lib-shared-common';
import { LibAuthService } from '../../service/lib-auth.service';
import { AUTH_ROUTER_TOKENS } from '../routes/router-tokens';

export const UnAuthenticatedInterceptor: HttpInterceptorFn = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const logger = inject(LoggerService);
	const authService = inject(LibAuthService);
	const router = inject(Router);

	return next(req).pipe(
		catchError((error: HttpErrorResponse) => {
			if (error.status === 401) {
				logger.warning('Intercept a 401.');
				authService.clearLoggedUser();
				const xx = router.routerState.snapshot.url;
				router.navigateByUrl(`/${AUTH_ROUTER_TOKENS.LOGIN}`);
				return EMPTY;
			}
			return throwError(() => error);
		})
	);
};
