import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import {
	InfoAlertComponent,
	LoadingButtonComponent,
	LoggerService,
} from '@webapp-nx-repo/lib-shared-common';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { LibAuthService } from '../../service/lib-auth.service';

@Component({
	selector: 'lib-email-not-verified',
	imports: [
		CommonModule,
		InfoAlertComponent,
		LoadingButtonComponent,
		MatIconModule,
		MatProgressSpinnerModule,
		MatButtonModule,
	],
	templateUrl: './email-not-verified.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EMailNotVerifiedComponent {
	private readonly destroyRef = inject(DestroyRef);
	menuService = inject(MenuService);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);
	private readonly authService = inject(LibAuthService);
	private readonly logger = inject(LoggerService);

	mailSent = signal<boolean>(false);
	loading = signal<boolean>(false);
	error = signal<string | null>(null);
	email = this.authService.loggedUser().email;

	constructor() {
		this.menuService.header.title.set('Email non verificata');
	}

	ngOnInit(): void {
		if (this.authService.isEmailVerified()) {
			this.router.navigateByUrl('/');
		}
	}

	sendNewActivationLink(): void {
		this.loading.set(true);
		this.authService
			.sendNewActivationLink()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (empty) => {
					this.loading.set(false);
					this.mailSent.set(true);
				},
				error: (err: string) => {
					this.loading.set(false);
				},
			});
	}
}
