import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { IUserInfo } from '../../model/user-info';
import { IUserType } from '../../model/user-type';
import { IUser } from '../../model/user';
import { HttpUsersService } from '../../service/http-users.service';


export type UsersResolverT = [IUser[], IUserInfo[]];


export const usersResolver: ResolveFn<UsersResolverT> = 
(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
)
: Observable<UsersResolverT> => {

    const s = inject(HttpUsersService);

    return forkJoin([
        s.accounts.all(),
        s.user_info.all(),
    ]);
}