import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private map = new Map<string, any>();

    set(key: string, value: any) {
        this.map.set(key, value);
    }

    has(key: string): boolean {
        return this.map.has(key);
    }

    get(key: string): any {
        return this.map.get(key);
    }

}
