<div class="p-4 mb-12">
    <form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
                
        <div class="border rounded p-4 bg-white flex flex-col">
            
            <h3 class="py-4 px-2">Modifica indirizzo e-mail di accesso</h3>
            
            <mat-form-field>
                <mat-label>Inserisci la tua email</mat-label>
                <input inputRefTrimValue matInput type="text" placeholder="nome@esempio.com" formControlName="email" required autocomplete="username">
                <mat-icon matSuffix [ngStyle]="{'color': (!!email_verified_at) ? 'green' : 'inherit' }">email</mat-icon>
                @if(formData.controls.email.hasError('required')) {
                    <mat-error>Inserire email</mat-error>
                }
                @if(formData.controls.email.hasError('email')) {
                    <mat-error>&apos;{{formData.controls.email.value}}&apos; non &egrave; una email valida</mat-error>
                }
                @if(formData.controls.email.hasError('maxLength')) {
                    <mat-error>Email troppo lunga</mat-error>
                }
                <mat-hint align="end">email {{(!!email_verified_at) ? 'verificata' : 'da verificare'}}</mat-hint>
            </mat-form-field>
    
        </div>
    
        @if(mail_saved()) {
            <lib-info-alert>
                Ti &egrave; stata inviata una mail all&apos;indirizzo &quot;{{ mail_saved() }}&quot;; 
                per attivare il tuo account, clicca sul pulsante
                <span class="font-bold">&quot;Verify Email Address&quot;</span> 
                all&apos;interno della mail.
            </lib-info-alert>
        }
        

        <lib-ok-cancel 
            label_ok="SALVA"
            label_cancel="Indietro"
            [disable_ok]="formData.invalid || formData.pristine || saving() || (!!mail_saved())" 
            [loading_ok]="saving()"
            (onOK)="save()"
            (onCancel)="back()" />
        
    </form>


    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }
</div>