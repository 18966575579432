import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { LibAuthService } from '../../service/lib-auth.service';
import { IUser } from '../../model/user';

export function loggedInRouteGuard() {
	return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		// const logger = inject(LoggerService);
		const authService = inject(LibAuthService);
		const router = inject(Router);

		/*
            isUserLoggedIn TRUE:  (ha fatto correttamente login)
                torno true e proseguo la navigazione

            isUserLoggedIn FALSE: 
                cookie buoni: 
                    uno che ha chiuso browser senza fare "esci", quando torna
                    ha ancora i cookie buoni ma authService.loggedUser() empty
                    quindi serve solo un refresh per lo user
                    dopodiche torno true e proseguo la navigazione
                no cookie: 
                    authService.getLoggedUser() --> 401 e l'interceptor lo 
                    manda a \login
        */

		const isUserLoggedIn =
			authService.isLoggedIn() &&
			(!authService.two_fa_enabled_and_confirmed() ||
				(authService.two_fa_enabled_and_confirmed() &&
					authService.two_factor_login));

		if (isUserLoggedIn) {
			return true;
		}

		// try to give it a chance .. if 401 --> login, 422 --> login2fa
		return authService.getLoggedUser().pipe(
			map((userInfo: IUser) => {
				if (authService.two_fa_enabled_and_confirmed()) {
					authService.two_factor_login = true;
				}
				return true;
			})
		);
	};
}
