<div class="p-5 mb-12 flex flex-col">
    
    @if(twoFA_enabled()) {
        <p>
            L&apos; autenticazione a due fattori &egrave; abilitata {{ twoFA_confirmed() ? 'e confermata' : 'ma da confermare' }}.
        </p>
    } @else {
        <p>
            L&apos; autenticazione a due fattori &egrave; disabilitata.
            Prima di abilitarla, verifica di avere una applicazione Time-based one-time password (TOTP) 
            come &quot;Google Authenticator&quot;, ti servir&agrave; per generare il codice OTP.
        </p>
    }

    <div class="border rounded mt-5 p-5 bg-white flex flex-col gap-4">
        
        <h3 class="pt-4">Gestisci l&apos;autenticazione a due fattori</h3>
        
        
        <mat-slide-toggle [formControl]="twoFA" class="mb-5">
            {{ twoFA_enabled() ? 'abilitata' : 'disabilitata' }}
        </mat-slide-toggle>            
    
        
        @if(twoFA_enabled()) {
            @if(svg_qr_code()) {
                <div [innerHTML]="(svg_qr_code()!) | safeHtml"></div>
            }
        
            @if(!twoFA_confirmed()) {                
                <p>
                    Conferma l&apos;abilitazione inserendo un OTP.
                </p>
                <mat-form-field appearance="outline">
                    <mat-label>codice otp</mat-label>
                    <input matInput type="text" [formControl]="twoFA_confirm" required>
                    <mat-icon matSuffix>qr_code_2</mat-icon>
                    @if(twoFA_confirm.errors?.['required']) {
                        <mat-error>inserire codice otp</mat-error>
                    }
                </mat-form-field>        
            
                <button type="button" mat-flat-button color="primary" 
                    [disabled]="twoFA_confirm.invalid || twoFA_confirm.pristine || saving()"                     
                    (click)="try_confirmTwoFactorAuthentication()">
                    VERIFICA
                </button>
            }
        }

        <div class="mt-5" [hidden]="!saving()">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        
    </div>

   
    

    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }
</div>