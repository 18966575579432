<div mat-dialog-content>
    <p>Conferma la tua password</p>
    <form [formGroup]="formData" autocomplete="off" novalidate class="mt-5 flex flex-col">
        <mat-form-field class="mt-2">
            <mat-label>Password</mat-label>
            <input inputRefTrimValue matInput type="password" placeholder="Password" formControlName="password" required autocomplete="current-password">
            <mat-icon matSuffix>vpn_key</mat-icon>
            @if(formData.controls.password.hasError('required')) {
                <mat-error>Inserire password</mat-error>
            }
        </mat-form-field>
    </form>

    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }
    

</div>

<div mat-dialog-actions>
    <lib-ok-cancel 
        class="block"
        label_ok="CONFERMA"
        label_cancel="Annulla"
        [disable_ok]="formData.invalid || formData.pristine || loading()" 
        [loading_ok]="loading()"
        (onOK)="ok()"
        (onCancel)="cancel()" />
</div>