import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
	ErrorAlertComponent,
	LoadingButtonComponent,
	OkCancelComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { LibAuthService } from '../service/lib-auth.service';

@Component({
	imports: [
		CommonModule,
		ErrorAlertComponent,
		OkCancelComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatDialogModule,
	],
	templateUrl: './password-confirmation-dialog.component.html',
	styleUrls: ['./password-confirmation-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordConfirmationDialogComponent {
	private readonly destroyRef = inject(DestroyRef);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);
	private readonly fb = inject(FormBuilder);
	private readonly snackBar = inject(MatSnackBar);

	private readonly libAuthService = inject(LibAuthService);
	menuService = inject(MenuService);

	public dialogRef = inject(
		MatDialogRef<PasswordConfirmationDialogComponent>
	);

	loading = signal<boolean>(false);
	error = signal<string | null>(null);

	formData: FormGroup<{
		password: FormControl<string | null>;
	}> = this.fb.group({
		password: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.maxLength(191),
		]),
	});

	ok(): void {
		this.loading.set(true);
		this.libAuthService
			.confirmPassword(this.formData.controls.password.value!)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (res) => {
					this.error.set('');
					this.loading.set(false);
					this.dialogRef.close(true);
				},
				error: (err: string) => {
					this.loading.set(false);
					this.error.set('Password errata');
				},
			});
	}
	cancel(): void {
		this.dialogRef.close(false);
	}
}
