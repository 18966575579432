import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    APP_INFO,
	HttpCrudService,
	HttpErrorService,
	ICity,
	LoggerService,
} from '@webapp-nx-repo/lib-shared-common';
import { IUserInfo } from '../model/user-info';
import { IUser } from '../model/user';
import { IUserType } from '../model/user-type';
import { USER_ENTITY_TRANSFORMATION_FN } from '../model/user.entity-transformation-fn';
import { catchError, finalize, Observable } from 'rxjs';


@Injectable({
	providedIn: 'root',
})
export class HttpUsersService {
	private readonly me = 'HttpUsersService';
	private readonly logger = inject(LoggerService);
	private readonly http = inject(HttpClient);
	private readonly httpErrorService = inject(HttpErrorService);
    private readonly app_info = inject(APP_INFO);

	//
	// account (IUser) 
	//
    accounts = new HttpCrudService<IUser>(
		`${this.me}.accounts`,
		`${this.app_info.srvUrl}api/v1/package/base-auth/user`,
	);

    //
	// user types
	//
    user_types = new HttpCrudService<IUserType>(
        `${this.me}.user_types`,
        `${this.app_info.srvUrl}api/v1/package/base-auth/user-type`,
    );

    //
    // user info
    //
    user_info = new HttpCrudService<IUserInfo>(
        `${this.me}.user_info`,
        `${this.app_info.srvUrl}api/v1/package/base-auth/user-info`,
        USER_ENTITY_TRANSFORMATION_FN.userInfo,
        USER_ENTITY_TRANSFORMATION_FN.userInfoSrv
    );

    getCities(): Observable<ICity[]> {
        const dbgMethodname = `${this.me}.getCities()`;
        const path = `${this.app_info.srvUrl}api/v1/package/base-auth/list-cities`;
        this.logger.log(`${dbgMethodname} - start ...`);

        return this.http.get<ICity[]>(path).pipe(
            catchError((err) => {
                return this.httpErrorService.handleError(
                    err,
                    dbgMethodname,
                    'Errore nel recupero delle informazioni.'
                );
            }),
            finalize(() => this.logger.log(`${dbgMethodname} - completed`))
        );
    }

    getCountries(): Observable<string[]> {
        const dbgMethodname = `${this.me}.getCountries()`;
        const path = `${this.app_info.srvUrl}api/v1/package/base-auth/list-countries`;
        this.logger.log(`${dbgMethodname} - start ...`);

        return this.http.get<string[]>(path).pipe(
            catchError((err) => {
                return this.httpErrorService.handleError(
                    err,
                    dbgMethodname,
                    'Errore nel recupero delle informazioni.'
                );
            }),
            finalize(() => this.logger.log(`${dbgMethodname} - completed`))
        );
    }
}
