import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	controlsMatcher,
	ErrorAlertComponent,
	InvalidParentErrorMatcher,
	LoadingButtonComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';
import { LibAuthService } from '../../../service/lib-auth.service';
import {
	EMPTY_PASSWORD_CONSTRAINTS,
	evalPasswordContraints,
	IPasswordContraints,
	pwdValidator,
} from '../../../utilities/password-utilities';
import { IUpdatePassword } from '../../../model/auth-model';
import { PwdStatusComponent } from '../../../auth/pwd-status/pwd-status.component';

@Component({
	imports: [
		CommonModule,
		ErrorAlertComponent,
		LoadingButtonComponent,
		PwdStatusComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
	],
	templateUrl: './change-password.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent {
	private readonly destroyRef = inject(DestroyRef);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	readonly fb = inject(FormBuilder);
	private snackBar = inject(MatSnackBar);

	private readonly libAuthService = inject(LibAuthService);
	readonly authService = inject(LibAuthService);
	menuService = inject(MenuService);

	saving = signal<boolean>(false);
	error = signal<string | null>(null);

	invalidParentErrorMatcher = new InvalidParentErrorMatcher();
	pwdStatus = signal<IPasswordContraints>(EMPTY_PASSWORD_CONSTRAINTS);
	passwordChanged = signal<boolean>(false);

	formData: FormGroup<{
		current_password: FormControl<string | null>;
		passwordGroup: FormGroup<{
			password: FormControl<string | null>;
			password_confirmation: FormControl<string | null>;
		}>;
	}> = this.fb.group({
		current_password: this.fb.control<string | null>(null, [
			Validators.required,
		]),
		passwordGroup: this.fb.group(
			{
				password: this.fb.control<string | null>(null, [
					Validators.required,
					pwdValidator,
				]),
				password_confirmation: this.fb.control<string | null>(null, [
					Validators.required,
				]),
			},
			{
				validators: controlsMatcher(
					'password',
					'password_confirmation'
				),
			}
		),
	});

	constructor() {
		this.menuService.header.set({
			leftCmd: { icon: 'keyboard_arrow_left', fn: () => this.back() },
			title: 'Cambio Password',
			rightCmd: null,
		});
		this.formData.controls.passwordGroup.controls.password.valueChanges.subscribe(
			(pwd: string | null) => {
				if (pwd) {
					this.pwdStatus.set(evalPasswordContraints(pwd));
				}
			}
		);
	}

	back(): void {
		this.router.navigate(['../'], { relativeTo: this.route });
	}

	save() {
		this.saving.set(true);
		const pwd: IUpdatePassword = {
			current_password: this.formData.controls.current_password.value!,
			password:
				this.formData.controls.passwordGroup.controls.password.value!,
			password_confirmation:
				this.formData.controls.passwordGroup.controls
					.password_confirmation.value!,
		};

		this.authService
			.updatePassword(pwd)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (xxx) => {
					this.error.set(null);
					this.saving.set(false);
					this.snackBar.open(
						'Password modificata correttamente',
						'',
						{ duration: 2000 }
					);
					this.back();
				},
				error: (err: string) => {
					this.saving.set(false);
					this.error.set(err);
				},
			});
	}
}
