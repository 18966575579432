import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibAuthService } from '../../service/lib-auth.service';
import { ProfileFormComponent } from '../profile-form/profile-form.component';
import { EMPTY_USER_INFO, IUserInfo } from '../../model/user-info';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';


@Component({
	imports: [
		CommonModule,
        ProfileFormComponent,
	],
	templateUrl: './profile.component.html',
	styles: [``],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent {
    private router = inject(Router);
    menuService = inject(MenuService);
    private route = inject(ActivatedRoute);
	private readonly authService = inject(LibAuthService);	
	logged_user_info = computed(() => this.authService.loggedUser().info ?? 
        {
            ...EMPTY_USER_INFO,
            user_id: this.authService.loggedUser().id,
        }
    );

    constructor() {
		this.menuService.header.set({
			leftCmd: {
				icon: 'keyboard_arrow_left',
				fn: () => this.back(),
			},
			title: 'Profilo',
			rightCmd: null,
		});
	}

    back() {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
    
    onSubmit($event: IUserInfo): void {
        this.authService.setLoggedUser({
			...this.authService.loggedUser(),
			info: $event,
		});
        this.back();
    }
}
