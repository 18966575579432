<div class="app-p4-mb8-mxauto-maxwscreenlg">
    <form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
                
        <div class="border rounded p-4 bg-white flex flex-col">
            
            <h2 class="py-4 px-2 flex items-end">
                <mat-icon class="mr-3">vpn_key</mat-icon>
                <span class="font-bold">Modifica la password di accesso</span>
            </h2>
            
            <mat-form-field>
                <mat-label>Password corrente</mat-label>
                <input inputRefTrimValue matInput type="password" placeholder="Password corrente" formControlName="current_password" autocomplete="current-password">
                @if(formData.controls.current_password.hasError('required')) {
                    <mat-error>Inserire la password</mat-error>
                }
            </mat-form-field>

            <div formGroupName="passwordGroup" class="flex flex-col">
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input inputRefTrimValue matInput type="password" formControlName="password" required autocomplete="new-password">
                    @if(formData.controls.passwordGroup.controls.password.hasError('required')) {
                        <mat-error>Inserire la password</mat-error>
                    }
                </mat-form-field>

                
                <lib-pwd-status [pwd_status]="pwdStatus()" />
                
                
                <mat-form-field>
                    <mat-label>Ripeti password</mat-label>
                    <input inputRefTrimValue matInput type="password" formControlName="password_confirmation" [errorStateMatcher]="invalidParentErrorMatcher" required autocomplete="new-password">                    
                    @if(formData.controls.passwordGroup.hasError('match')) {
                        <mat-error>Le password sono diverse</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
    
        <div class="mt-8 flex flex-row-reverse">
            <lib-loading-button
                [loading]="saving()" 
                [disabled]="formData.invalid || formData.pristine || saving()" 
                (onClick)="save()">
                SALVA
            </lib-loading-button>
                
            <button type="button" (click)="back()" mat-stroked-button class="me-1">Indietro</button>
        </div>
        
    </form>


    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }
    
</div>