<div class="p-5"> <!-- class="mx-auto mb_maxwidth p-3" -->

    <p class="mt-5">
        Per poter proseguire devi prima attivare il tuo account.
    </p>

    <p class="mt-5">
        Controlla la tua mail &quot;{{ email }}&quot; e 
        clicca sul pulsante
        <span class="font-bold">&quot;Verify Email Address&quot;</span> 
    </p>

    <p class="mt-5">
        nota: se non trovi la mail, controlla nello spam ... purtoppo a volte finisce l&iacute;,
        altrimenti invia un nuovo link di verifica.
    </p>

    <div class="text-center mt-5">
        
        @if(mailSent()) {
            <lib-info-alert icon="mail">
                mail inviata !
            </lib-info-alert>
        } @else {
            <lib-loading-button
                
                [loading]="loading()" 
                [disabled]="loading()"
                (onClick)="sendNewActivationLink()">
                invia un nuovo link di verifica
            </lib-loading-button>
        }

    </div>

</div>
