import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    signal,
    input,
    output,
    effect,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { _isNumberValue } from '@angular/cdk/coercion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DateTime } from 'luxon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { BaseCrudEntity } from '@webapp-nx-repo/lib-shared-shell';
import {
    CityControlComponent,
    ErrorAlertComponent,
    HttpCrudService,
    ICity,
    ICityDetails,
    InputRefTrimValueDirective,
    OkCancelComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { LibAuthService } from '../../service/lib-auth.service';
import { PROFILE_CONFIG } from '../../provider/profile-config';
import { forkJoin } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EMPTY_USER_INFO, IUserInfo } from '../../model/user-info';
import { IUserType } from '../../model/user-type';
import { HttpUsersService } from '../../service/http-users.service';

@Component({
    selector: 'lib-profile-form',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatLuxonDateModule,
        CityControlComponent,
        InputRefTrimValueDirective,
        ErrorAlertComponent,
        OkCancelComponent,
    ],
	templateUrl: './profile-form.component.html',
	styleUrl: './profile-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileFormComponent extends BaseCrudEntity<IUserInfo> {
	private readonly authService = inject(LibAuthService);
	readonly profile_cfg = inject(PROFILE_CONFIG);
	private readonly user_info_service = inject(HttpUsersService);
	private readonly fb = inject(FormBuilder);

    readonly countries = signal<string[]>([]);
	readonly loadingCities = signal<boolean>(false);

    user_types = input.required<IUserType[]>();
    user_info = input.required<IUserInfo>();
    onSubmit = output<IUserInfo>();
    onCancel = output<void>();
    readonly userCanChangeType = computed(() => this.user_types().length > 0 && this.authService.loggedUser().id === 1);
    // isLoading = output<boolean>();
    
	formData: FormGroup<{
        user_type_id: FormControl<number | null>;
		name: FormControl<string | null>;
		surname: FormControl<string | null>;
        middlename: FormControl<string | null>;
		cf?: FormControl<string | null>;
		date_of_birth?: FormControl<DateTime | null>;
		birth_place?: FormControl<ICityDetails | null>;
		place_of_residence?: FormControl<ICityDetails | null>;
		phone?: FormControl<string | null>;
        iban?: FormControl<string | null>;
	}> = this.fb.group({
        user_type_id: this.fb.control<number | null>(null),
		name: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.minLength(1),
			Validators.maxLength(191),
		]),
		surname: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.minLength(1),
			Validators.maxLength(191),
		]),
        middlename: this.fb.control<string | null>(null, [
			Validators.minLength(1),
			Validators.maxLength(191),
		]),
	});
    

	readonly cities = signal<ICity[]>([]);
	readonly cities_without_zip = computed<ICity[]>(() =>
		this.cities().filter(function (v, i, self) {
			return i == self.findIndex((c) => c.city === v.city);
		})
	);
	

	constructor() {
		super();
		this.buildForm();
        // Effect to update form when user_info changes
        effect(() => this.updateForm());
        // effect(() => this.isLoading.emit(this.loadingCities() || this.loadingUserTypes()));
	}

    private buildForm() {
        if (this.profile_cfg.cf) {
			this.formData.addControl(
				'cf',
				this.fb.control<string | null>(null, [
					Validators.required,
					Validators.minLength(16),
					Validators.maxLength(16),
				])
			);
		}
		if (this.profile_cfg.date_of_birth) {
			this.formData.addControl(
				'date_of_birth',
				this.fb.control<DateTime | null>(null, [Validators.required])
			);
		}
		if (this.profile_cfg.birth_place) {
			this.formData.addControl(
				'birth_place',
				this.fb.control<ICityDetails | null>(
					{ value: null, disabled: true },
					[Validators.required]
				)
			);
		}
		if (this.profile_cfg.place_of_residence) {
			this.formData.addControl(
				'place_of_residence',
				this.fb.control<ICityDetails | null>(
					{ value: null, disabled: true },
					[Validators.required]
				)
			);
		}
		if (this.profile_cfg.phone) {
			this.formData.addControl(
				'phone',
				this.fb.control<string | null>(null, [
					Validators.required,
					Validators.minLength(1),
					Validators.maxLength(20),
				])
			);
		}
        if (this.profile_cfg.iban) {
			this.formData.addControl(
				'iban',
				this.fb.control<string | null>(null, [
					Validators.minLength(20),
					Validators.maxLength(30),
				])
			);
		}
    }
    private updateForm() {
        this.formData.patchValue({
			name: this.user_info().name,
			surname: this.user_info().surname,
            user_type_id: this.user_info().user_type_id,
		});

		if (this.profile_cfg.cf && this.user_info().cf) {
			this.formData.controls.cf!.setValue(this.user_info().cf);
		}
		if (
			this.profile_cfg.date_of_birth &&
			this.user_info().date_of_birth
		) {
			this.formData.controls.date_of_birth!.setValue(
				this.user_info().date_of_birth
			);
		}
		if (this.profile_cfg.phone && this.user_info().phone) {
			this.formData.controls.phone!.setValue(
				this.user_info().phone
			);
		}
    }

	protected override _ngOnInit(): void {
		this.updateForm();        
		if (
			this.profile_cfg.birth_place ||
			this.profile_cfg.place_of_residence
		) {
			this.loadCitiesAndCountries();
		}
        
	}

	private loadCitiesAndCountries() {
		this.loadingCities.set(true);
		forkJoin([
			this.user_info_service.getCities(),
			this.user_info_service.getCountries(),
		])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: ([cities, countries]) => {
					this.cities.set(cities);
                    // Aggiungo Italia anche in testa
                    const index = countries.indexOf('Italia');
                    if (index !== -1) {            
                        countries.unshift('Italia');
                    }
					this.countries.set(countries);

					if (this.profile_cfg.birth_place) {
						const city =
							cities.find(
								(x) =>
									x.city === this.user_info().birth_city &&
									x.province === this.user_info().birth_prov
							) ?? null;
						const cityDetails: ICityDetails = {
							country: this.user_info().birth_country,
							city,
						};
						this.formData.controls.birth_place!.setValue(
							cityDetails
						);
						this.formData.controls.birth_place?.enable();
					}

					if (this.profile_cfg.place_of_residence) {
						const city =
							cities.find(
								(x) =>
									x.city === this.user_info().birth_city &&
									x.province === this.user_info().birth_prov &&
									x.zip === this.user_info().por_zip
							) ?? null;
						const cityDetails: ICityDetails = {
							country: this.user_info().por_country,
							address: this.user_info().por_address,
							city,
						};
						this.formData.controls.place_of_residence!.setValue(
							cityDetails
						);
						this.formData.controls.place_of_residence?.enable();
					}

                    this.loadingCities.set(false);
				},
				error: (err) => {
					this.error.set(err);
                    this.loadingCities.set(false);					
				},
			});
	}
    

	// Implementation of: BaseCrudEntity<ICounter>
	//
	protected get_item(): IUserInfo {
		const item: IUserInfo = {
			...EMPTY_USER_INFO,
			id: this.user_info().id,
			user_id: this.user_info().user_id,
			user_type_id: 
                this.formData.value.user_type_id ?? EMPTY_USER_INFO.user_type_id,
			name: 
                this.formData.value.name!,
			surname: 
                this.formData.value.surname!,
			cf: 
                this.formData.value.cf ?? EMPTY_USER_INFO.cf,
			date_of_birth:
				this.formData.value.date_of_birth ??
				EMPTY_USER_INFO.date_of_birth,

			birth_country:
				this.formData.value.birth_place?.country ??
				EMPTY_USER_INFO.birth_country,
			birth_city:
				this.formData.value.birth_place?.city?.city ??
				EMPTY_USER_INFO.birth_city,
			birth_prov:
				this.formData.value.birth_place?.city?.province ??
				EMPTY_USER_INFO.birth_prov,

			por_country:
				this.formData.value.place_of_residence?.country ??
				EMPTY_USER_INFO.por_country,
			por_address:
				this.formData.value.place_of_residence?.address ??
				EMPTY_USER_INFO.por_address,
			por_city:
				this.formData.value.place_of_residence?.city?.city ??
				EMPTY_USER_INFO.por_city,
			por_zip:
				this.formData.value.place_of_residence?.city?.zip ??
				EMPTY_USER_INFO.por_zip,
			por_prov:
				this.formData.value.place_of_residence?.city?.province ??
				EMPTY_USER_INFO.por_prov,

			phone: this.formData.value.phone ?? EMPTY_USER_INFO.phone,
		};

		return item;
	}
	protected get_http_crud_service(): HttpCrudService<IUserInfo> {
		return this.user_info_service.user_info;
	}
	protected on_save_confirm_dialog_mgs(): string[] {
		return [
			`Salvo i dati di profilo per l\'utente \"${this.formData.controls.name.value}\" ?`,
		];
	}
	protected on_delete_confirm_dialog_mgs(): string[] {
		return [`Vuoi eliminare il profilo ?`];
	}
	protected on_insert_complete_msg(): string {
		return 'Profilo inserito';
	}
	protected on_update_complete_msg(): string {
		return 'Profilo aggiornato';
	}
	protected on_delete_complete_msg(): string {
		return 'Profilo eliminato';
	}

	protected override on_insert_complete_fn(info: IUserInfo): void {
        this.onSubmit.emit(info);
	}
	protected override on_update_complete_fn(info: IUserInfo): void {
        this.onSubmit.emit(info);
	}
	protected override on_delete_complete_fn(id: number): void {
        this.onSubmit.emit(EMPTY_USER_INFO);
	}

    protected override on_insert_complete_do_back(): boolean { return false; };
    protected override on_update_complete_do_back(): boolean { return false; };
    protected override on_delete_complete_do_back(): boolean { return false; };
}

