import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EMPTY_USER_INFO, IUserInfo } from '../../model/user-info';
import { ProfileFormComponent } from '../../account/profile-form/profile-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { UserInfoResolverT } from './user-info-resolver.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { MenuService } from '@webapp-nx-repo/lib-shared-shell';

@Component({
	selector: 'lib-user-info',
	imports: [
        CommonModule,
        ProfileFormComponent,
    ],
	templateUrl: './user-info.component.html',
	styleUrl: './user-info.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {    
    private router = inject(Router);
    menuService = inject(MenuService);
    private route = inject(ActivatedRoute);

    data$ = this.route.data.pipe(
		map((data) => data['data'] as UserInfoResolverT),
        map(([user_info, user_types]) => ({user_info, user_types})),
	);
    data = toSignal(this.data$, {initialValue: {
        user_info: EMPTY_USER_INFO,
        user_types: [],
    }});

    constructor() {
		this.menuService.header.set({
			leftCmd: {
				icon: 'keyboard_arrow_left',
				fn: () => this.back(),
			},
			title: 'Profilo',
			rightCmd: null,
		});
	}

    onSubmit($event: IUserInfo): void {
        this.back();
    }

    back() {
        this.router.navigate(['../../'], { relativeTo: this.route });
    }
}
