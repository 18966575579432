export enum ACCOUNT_ROUTER_TOKENS {
    BASE_URL        = 'account',
    PROFILE         = 'profile',
    SECURITY        = 'security',
    SECURITY_MAIL   = 'email',
    SECURITY_PWD    = 'password',
    SECURITY_2FA    = 'two-factor',
    
    USERS           = 'users',
    USER_INFO       = 'info',
    USER_ACCOUNT    = 'account',
}
